<template>
  <v-card>
    <v-card-title class="text-h5" primary-title>
      <v-icon :left="true">
        {{ $vuetify.icons.values.alert }}
      </v-icon>
      <h6 class="text-h6">
        {{
          getData && getData.list.length > 1
            ? $trans("delete_users")
            : $trans("delete_user")
        }}
      </h6>
    </v-card-title>

    <v-card-text>
      <p class="text-subtitle-1">
        {{
          getData && getData.list.length > 1
            ? $trans("remove_accounts_warning")
            : $trans("remove_account_warning")
        }}
      </p>
      <p class="accent_light--text text-subtitle-1">
        {{ $trans("no_way_to_undo_action") }}
      </p>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn color="light" text @click="$emit('close')">
        {{ $trans("cancel") }}
      </v-btn>
      <v-btn
        :disabled="isLoading"
        color="accent_light"
        text
        @click="deleteMultiple(getData)"
      >
        {{ $trans("remove") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import userActions from "@/calendesk/mixins/userActions";

export default {
  name: "RemoveUserDialog",
  mixins: [userActions],
  computed: {
    getData() {
      return this.getConfirmDialog.data;
    },
  },
};
</script>
